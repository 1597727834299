import { createRouter, createWebHistory } from 'vue-router'
import { helperToken, helperServiceWorker } from '../helpers/index'
import admin from './admin.js'
import autenticacao from './autenticacao.js'

const routes = [...autenticacao, ...admin]

const router = createRouter({
  routes: routes,
  history: createWebHistory(),
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  }
})

router.afterEach((to) => {
  document.title = to.meta.title || 'Admin UnicPages'
})

router.beforeEach((to, _from, next) => {
  if (to.meta.logged) {
    if (!helperToken.detectarTokenOk()) router.push('/')
  }

  helperServiceWorker.ativar()
  next()
})

export default router
